import React, { useRef, useEffect } from 'react';

import {
  WebGLRenderer,
  OrthographicCamera,
  Scene,
  PlaneGeometry,
  Vector3,
  ShaderMaterial,
  Mesh,
} from 'three';

const NavbarBackground = p => {
  const instanceRef = useRef(null);
  const canvasRef = useRef(null);
  const sceneRef = useRef(new Scene());
  const planeRef = useRef(new PlaneGeometry(2, 2));
  const cameraRef = useRef(new OrthographicCamera(
    -1, // left
    1, // right
    1, // top
    -1, // bottom
    -1, // near,
    1, // far
  ))

  useEffect(() => {

    if (canvasRef.current) {

      if (instanceRef.current) return;

      const canvas = canvasRef.current;

      const renderer = new WebGLRenderer({ canvas, antialias: false });
      renderer.autoClearColor = false;

      const camera = cameraRef.current;
      const scene = sceneRef.current;
      const plane = planeRef.current;

      const fragmentShader = `
          #include <common>

          uniform vec3 iResolution;
          uniform float iTime;

          // license: Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
          #define S(a,b,t) smoothstep(a,b,t)

          mat2 Rot(float a)
          {
              float s = sin(a);
              float c = cos(a);
              return mat2(c, -s, s, c);
          }


          // Created by Johan Nel
          // License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
          vec2 hash( vec2 p )
          {
              p = vec2( dot(p,vec2(2127.1,81.17)), dot(p,vec2(1269.5,283.37)) );
            return fract(sin(p)*43758.5453);
          }

          float noise( in vec2 p )
          {
              vec2 i = floor( p );
              vec2 f = fract( p );
            
            vec2 u = f*f*(3.0-2.0*f);

              float n = mix( mix( dot( -1.0+2.0*hash( i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ), 
                                  dot( -1.0+2.0*hash( i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
                            mix( dot( -1.0+2.0*hash( i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ), 
                                  dot( -1.0+2.0*hash( i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), u.y);
            return 0.9 + 0.9*n;
          }


          void mainImage( out vec4 fragColor, in vec2 fragCoord )
          {
              vec2 uv = fragCoord/iResolution.xy;
              float ratio = iResolution.x / iResolution.y;

              vec2 tuv = uv;
              tuv -= .5;

              // rotate with Noise
              float degree = noise(vec2(iTime*.1, tuv.x*tuv.y));

              tuv.y *= 1./ratio;
              tuv *= Rot(radians((degree-.14)*720.+180.));
              tuv.y *= ratio;

              
              // Wave warp with sin
              float frequency = 5.;
              float amplitude = 30.;
              float speed = iTime * 1.;
              tuv.x += sin(tuv.y*frequency+speed)/amplitude;
              tuv.y += sin(tuv.x*frequency*1.5+speed)/(amplitude*.5);
              
                
              // draw the image
              vec3 colorGreen = vec3(0.1,0.9,.78);
              vec3 colorDeepBlue = vec3(.192, .384, .933);

              vec3 colorRed = vec3(0.922,0.1,0.345);
              vec3 colorBlue = vec3(0.18,0.192,0.99);
              vec3 colorOrange = vec3(0.965,0.573,0.118);
              
              vec3 layer1 = mix(colorGreen, colorDeepBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));
              vec3 layer2 = mix(colorRed, colorBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));
              vec3 layer3 = mix(colorOrange, colorRed, S(-.3, .2, (tuv*Rot(radians(-5.))).x));

              vec3 compA = mix(layer1, layer2, S(.5, -.3, tuv.y));
              vec3 compB = mix(layer2, layer3, S(.5, -.3, tuv.y));

              vec3 finalComp = mix(compA, compB, S(.5, -.3, tuv.y));

              vec3 col = finalComp;
              
              fragColor = vec4(col,1.0);

            }

            void main() {
              mainImage(gl_FragColor, gl_FragCoord.xy);
            }
        `;

      const uniforms = {
        iTime: { value: 0 },
        iResolution: { value: new Vector3() },
      };

      const material = new ShaderMaterial({
        fragmentShader,
        uniforms,
      });

      scene.add(new Mesh(plane, material));

      const resizeRendererToDisplaySize = (renderer) => {
        const canvas = renderer.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
          renderer.setSize(width, height, false);
        }
        return needResize;
      }

      const render = (time) => {

        time *= 0.001;  // convert to seconds

        resizeRendererToDisplaySize(renderer);

        const canvas = renderer.domElement;
        uniforms.iResolution.value.set(canvas.width, canvas.height, 1);
        uniforms.iTime.value = time;

        renderer.render(scene, camera);

        requestAnimationFrame(render);

      }

      requestAnimationFrame(render);
      instanceRef.current = true;

    }
  }, [])

  return <canvas className="absolute w-full h-full top-0" ref={canvasRef} {...p} />

}

export default NavbarBackground;