// Components
import OmnilinkBackground from '../components/OmnilinkBackground';
import Navbar from '../components/Navbar';
import ContactUsForm from '../components/ContactUsForm';

// ----------------------------------------------------------------------
const Home = ()=> {

  const navigation = [
    { name: 'Our Services', href: '#websites' },
    { name: 'Contact Us', href: '#contact-us' },
    // { name: 'Get a quote', href: '/get-a-quote' },
  ]

  return (
    <main>

      <section className="relative mx-auto">

        <OmnilinkBackground />
        <Navbar navigation={navigation} />

        <div className="relative sm:text-center px-8 mt-10 lg:text-left x-4 sm:mt-12 sm:px-6 md:mt-20 lg:mt-20 lg:px-8 xl:mt-24 pb-8 sm:pb-16 xl:pb-28 lg:pb-20 md:pb-20 max-w-6xl mx-auto">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block text-gray-50 xl:inline">Modern times require</span>{' '}
            <span className="block mix-blend-overlay xl:inline">modern solutions</span>
          </h1>
          <p className="mt-3 text-white sm:mx-auto sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0">
            We provide custom software based solutions and services to boost your business.
          </p>
          <div className="mt-5 w-full sm:w-auto sm:mt-8 flex flex-wrap sm:justify-center lg:justify-start ">
            <div className="mt-3 sm:w-auto rounded-full flex justify-center items-center mb-2 sm:mt-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:shadow-md">
              <a
                href="#contact-us"
                className="px-2 py-1 leading-2 text-center text-xs sm:w-auto font-medium rounded-full transition-all ease-in-out hover:shadow-md hover:shadow-indigo-400/50 border-2 border-transparent sm:px-8 sm:py-3 sm:text-base text-indigo-50 hover:text-indigo-500 hover:bg-indigo-50 md:py-2 md:px-5 md:text-md"
              >
                Contact Us
              </a>
            </div>
            <a href="#websites"
              className="px-2 py-1 mt-3 leading-2 text-center text-xs sm:w-auto sm:font-medium mb-2 sm:mt-0 ml-2 sm:ml-3 rounded-full transition-all ease-in-out hover:shadow-md hover:shadow-indigo-400/50 border-2 border-indigo-50 sm:px-8 sm:py-3 sm:text-base text-indigo-50 hover:text-indigo-500 hover:bg-indigo-50 md:py-2 md:px-5 md:text-md"
            >
              Websites
            </a>
            <a href="#apps"
              className="px-2 py-1 mt-3 leading-2 text-center text-xs sm:w-auto sm:font-medium mb-2 sm:mt-0 ml-2 sm:ml-3 rounded-full transition-all ease-in-out hover:shadow-md hover:shadow-indigo-400/50 border-2 border-indigo-50 sm:px-8 sm:py-3 sm:text-base text-indigo-50 hover:text-indigo-500 hover:bg-indigo-50 md:py-2 md:px-5 md:text-md"
            >
              Apps
            </a>
          </div>
        </div>

      </section>

      <section id="websites" className="sm:text-center lg:text-left px-8 mt-20 sm:mt-20 sm:px-6 md:mt-24 lg:mt-30 lg:px-8 xl:mt-20 pb-8 sm:pb-16 xl:pb-10 xl:pt-20 lg:pb-10 md:pb-20 flex flex-col-reverse items-center lg:flex-row max-w-6xl mx-auto">
        <div className="lg:mr-8 lg:mb-0">
          <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-4xl md:text-5xl mb-6">
            <span className="block mb-2">Web Development</span>{' '}
            <span className="block bg-clip-text text-2xl sm:text-3xl text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">Websites and Web-Apps customisable to your specification</span>
          </h1>
          <p className="text-gray-500 mb-6">
            Getting into the digital market is a colossal task to take on. Why not give us the opportunity to do the heavy lifting for you? With many years of industry experience, we have the knowledge to make your vision a reality.
          </p>
          <ul className="flex flex-col items-start sm:items-center lg:items-start">
            {
              [
                "Business Websites",
                "Online Store / E-Commerce",
                "Blog / CMS",
                "Landing Pages",
                "Web Portals",
                "API service integration and automation",
              ].map( (text, idx) => (
                <li key={idx} className="flex flex-row items-center">
                  <svg className="fill-indigo-600" width="18" height="18" viewBox="0 0 16 16"><path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z" fillRule="evenodd"></path></svg>
                  <span className="ml-2 text-indigo-600 font-medium">{text}</span>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="lg:mx-8 max-w-xs lg:max-w-md mb-8 lg:mb-0">
          <img className="w-full h-auto shadow-lg shadow-gray-200 rounded" style={{ aspectRatio: 1.63, transform: 'rotate3d(-19, 41, 11, 25deg)' }} src="/images/website_mockup.webp" alt="L&A Extracts" />
        </div>
      </section>

      <section id="apps" className="sm:text-center lg:text-left px-8 mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-20 pb-8 sm:pb-16 xl:pb-28 lg:pb-28 md:pb-20 flex items-center flex-col lg:flex-row max-w-6xl mx-auto">
        <div className="lg:mx-8 w-40 max-w-xs lg:w-full mb-4 lg:mb-0">
          <img className="w-full h-auto" style={{ aspectRatio: 'calc(105/188)' }} src="/images/phones.webp" alt="OmnilinkDigital App Mockup" />
        </div>
        <div className="lg:ml-16 mb-16 lg:mb-0">
          <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-4xl md:text-5xl mb-6">
            <span className="block mb-2">Mobile App Development</span>{' '}
            <span className="block bg-clip-text text-2xl sm:text-3xl text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">iOS & Android cross-platform applications</span>
          </h1>
          <p className="text-gray-500 mb-6">
            Mobile apps are part of our everyday lives and help us to perform certain tasks effectively. They are praised for their ease of use and accessibility of information. Together we can bridge the gap between your services and your customers.
          </p>
          <ul className="flex flex-col items-start sm:items-center lg:items-start">
            {
              [
                "App Store and Google Play Store listed",
                "Customisable",
                "User Friendly",
                "Internal Distribution",
              ].map((text, idx) => (
                <li key={idx} className="flex flex-row items-center">
                  <svg className="fill-indigo-600" width="18" height="18" viewBox="0 0 16 16"><path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z" fillRule="evenodd"></path></svg>
                  <span className="ml-2 text-indigo-600 font-medium">{text}</span>
                </li>
              ))
            }
          </ul>
        </div>
      </section>

      <section id="contact-us" className="sm:text-center xl:mt-12 lg:text-left px-4 pt-10 sm:pt-12 sm:px-6 md:pt-16 lg:px-8 xl:pt-20 pb-8 sm:pb-16 xl:pb-20 lg:pb-28 md:pb-20 flex items-center justify-center flex-col lg:flex-row bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
        <h1 className="text-4xl lg:mr-24 font-bold tracking-tight text-white sm:text-4xl md:text-5xl mb-6">
          <span className="block mb-2">Contact Us</span>{' '}
        </h1>
        <ContactUsForm />
      </section>


    </main>
  )
}
// ----------------------------------------------------------------------
export default Home;