// React
import { useState } from 'react';

// Libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// ----------------------------------------------------------------------
const FormSchema = yup.object({
  firstName: yup.string().required('First Name is required!').ensure(),
  lastName: yup.string().required('Last Name is required!').ensure(),
  email: yup.string().email().required('Email is required!').ensure(),
  number: yup.string().required('Number is required!').ensure(),
  message: yup.string().required('Message is required').ensure(),
})
// ----------------------------------------------------------------------
const ContactUsForm = ()=> {

  const [ success, setSuccess ] = useState(false);

  const formik = useFormik({
    initialValues: FormSchema.cast({}),
    validationSchema: FormSchema,
    onSubmit: async values => {

      const response = await fetch('/api/send-contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });

      if( response.status === 200 ) {
        setSuccess(true);
        formik.resetForm();
      }

    }
  });

  const onReset = ()=> {
    setSuccess(false);
    formik.resetForm();
  }

  return (
    <div className="w-full max-w-sm">
      <form onSubmit={formik.handleSubmit}>
        <div className="overflow-hidden shadow-lg rounded-2xl sm:rounded-3xl divide-y divide-gray-200">
          <div className="bg-white px-4 py-5 sm:p-6">
            <div className="grid grid-cols-1 gap-4">
              
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <input
                  type="text"
                  name="firstName"
                  autoComplete="given-name"
                  className="px-4 py-2 mt-1 block w-full rounded-full border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:shadow-md sm:text-sm"
                  placeholder="eg John"
                  id="first-name"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  disabled={success}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <input
                  type="text"
                  name="lastName"
                  autoComplete="family-name"
                  className="px-4 py-2 mt-1 block w-full rounded-full border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:shadow-md sm:text-sm"
                  placeholder="eg Doe"
                  id="last-name"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  disabled={success}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <input
                  type="text"
                  name="email"
                  autoComplete="email"
                  className="px-4 py-2 mt-1 block w-full rounded-full border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:shadow-md sm:text-sm"
                  placeholder="eg email@domain.com"
                  id="email-address"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled={success}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                  Number
                </label>
                <input
                  type="text"
                  name="number"
                  className="px-4 py-2 mt-1 block w-full rounded-full border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:shadow-md sm:text-sm"
                  placeholder="eg 012 123 1234"
                  id="number"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.number}
                  disabled={success}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  type="text"
                  name="message"
                  className="resize-none px-4 py-2 mt-1 block w-full rounded-2xl border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:shadow-md sm:text-sm"
                  placeholder="Your message"
                  id="message"
                  rows="5"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  disabled={success}
                />
              </div>

            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            {
              !success &&
              <>
                {
                  formik.dirty &&
                  <button onClick={onReset} className="inline-flex justify-center rounded-full py-2 px-8 text-sm font-medium text-orange-500">Reset</button>
                }
                <button
                  type="submit"
                  className={
                    formik.isValid && formik.dirty ?
                    'inline-flex justify-center rounded-full py-2 px-8 text-sm font-medium text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 transition-all hover:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    :
                    'inline-flex justify-center rounded-full py-2 px-8 text-sm font-medium text-white bg-gray-500 transition-all focus:outline-none active:outline-none'
                  }
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty && !formik.isValid}
                >
                  Send
                </button>
              </>

            }
            {
              success &&
              <>
                <button onClick={onReset} className="inline-flex justify-center rounded-full py-2 px-8 text-sm font-medium text-orange-500">Reset</button>
                <div className="inline-flex justify-center rounded-full py-2 px-8 text-sm font-medium text-indigo-500">Sent!</div>
              </>
            }
          </div>
        </div>
      </form>
    </div>
  )
}
// ----------------------------------------------------------------------
export default ContactUsForm;