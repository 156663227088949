// ----------------------------------------------------------------------
const Navbar = ({ navigation })=> {
  return (
    <nav className="relative max-w-6xl mx-auto flex items-center justify-between sm:h-10 lg:justify-start px-8 pt-10 sm:px-6 lg:px-8 xl:pt-12" aria-label="Global">
      <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
        <div className="flex w-full items-center justify-between md:w-auto">
          <a href="#">
            <span className="sr-only">Omnilink Digital</span>
            <img
              alt="Omnilink Digital"
              className="h-8 w-auto sm:h-10"
              src="/images/omnilinkdigital_logo.svg"
              width={278}
              height={40}
            />
          </a>
          <div className="-mr-2 flex items-center md:hidden">
            {/* <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button> */}
          </div>
        </div>
      </div>
      <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
        {
          navigation.map((item) => (
            <a key={item.name} href={item.href} className="font-medium text-white transition-all hover:underline">
              {item.name}
            </a>
          ))
        }
      </div>
    </nav>
  )
}
// ----------------------------------------------------------------------
export default Navbar