// Libraries
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import './App.css'

// Pages
import Home from "./pages/Home";

// ----------------------------------------------------------------------
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
]);
// ----------------------------------------------------------------------
const App = () => {
  return <RouterProvider router={router} />
}
// ----------------------------------------------------------------------
export default App